<template>
  <div v-if="isOpen" class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
    <div class="bg-white p-8 rounded-lg max-w-5xl w-11/12 h-4/5 overflow-y-auto relative">
      <button
          @click="closeModal"
          class="absolute top-4 right-4 bg-red-500 text-white font-bold text-lg w-10 h-10 rounded-full flex items-center justify-center hover:bg-red-600 shadow-lg"
          aria-label="Close Modal"
      >
        <i class="fas fa-times"></i>
      </button>
      <div class="text-center mb-6">
        <img :src="flagUrl" alt="Country flag" class="inline-block w-24 h-auto mb-4">
        <h2 class="text-3xl font-bold">{{ content.name }}</h2>
        <p class="text-xl">{{ content.territorial_divisions.region }}, {{ content.country }}</p>
        <p class="text-md mt-2">{{ content.description }}</p>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <!-- History -->
        <div class="col-span-full">
          <h3 class="text-2xl font-semibold mb-4">History</h3>
          <div class="bg-lightBlue p-4 rounded-lg shadow-md mb-4">
            <p>{{ content.history }}</p>
          </div>
        </div>
        <!-- Geography -->
        <div class="col-span-full">
          <h3 class="text-2xl font-semibold mb-4">Geography</h3>
          <div class="bg-lightBlue p-4 rounded-lg shadow-md mb-4">
            <p>{{ content.geography }}</p>
          </div>
        </div>
        <!-- Landmarks -->
        <div v-if="content.landmarks.length">
          <h3 class="text-2xl font-semibold mb-4">Landmarks</h3>
          <div v-for="landmark in content.landmarks" :key="landmark.name" class="bg-lightBlue p-4 rounded-lg shadow-md mb-4">
            <h4 class="text-xl font-bold">{{ landmark.name }}</h4>
            <p>{{ landmark.description }}</p>
            <div class="flex justify-end mt-2 space-x-2">
              <button
                  :href="landmark.wikipedia_url"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="text-gray-600 bg-white border border-gray-300 rounded-lg p-2 hover:bg-gray-100 shadow-md"
                  aria-label="Wikipedia"
              >
                <i class="fab fa-wikipedia-w"></i>
              </button>
              <button
                  @click="openModalWithImages(`${landmark.name} ${content.territorial_divisions.city} in ${content.territorial_divisions.country}`)"
                  class="text-white bg-blue-500 hover:bg-blue-600 font-medium rounded-lg px-4 py-2 shadow-md"
              >
                <i class="fas fa-camera mr-2"></i>
                View Images
              </button>
              <button
                  @click="fetchMoreInformation(`${landmark.name} ${content.territorial_divisions.city} in ${content.territorial_divisions.country}`)"
                  class="text-white bg-green-500 hover:bg-green-600 font-medium rounded-lg px-4 py-2 shadow-md"
              >
                <i class="fas fa-info-circle mr-2"></i>
                More Information
              </button>
            </div>
          </div>
        </div>
        <!-- Famous Places -->
        <div v-if="content.famous_places.length" class="col-span-full">
          <h3 class="text-2xl font-semibold mb-4">Famous Places</h3>
          <div v-for="place in content.famous_places" :key="place.name" class="bg-lightBlue p-4 rounded-lg shadow-md mb-4">
            <h4 class="text-xl font-bold">{{ place.name }}</h4>
            <p>{{ place.description }}</p>
            <div class="flex justify-end mt-2 space-x-2">
              <button
                  :href="place.wikipedia_url"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="text-gray-600 bg-white border border-gray-300 rounded-lg p-2 hover:bg-gray-100 shadow-md"
                  aria-label="Wikipedia"
              >
                <i class="fab fa-wikipedia-w"></i>
              </button>
              <button
                  @click="openModalWithImages(place.name + ' ' + content.territorial_divisions.country)"
                  class="text-white bg-blue-500 hover:bg-blue-600 font-medium rounded-lg px-4 py-2 shadow-md"
              >
                <i class="fas fa-camera mr-2"></i>
                View Images
              </button>
              <button
                  @click="fetchMoreInformation(place.name + ' ' + content.territorial_divisions.country)"
                  class="text-white bg-green-500 hover:bg-green-600 font-medium rounded-lg px-4 py-2 shadow-md"
              >
                <i class="fas fa-info-circle mr-2"></i>
                More Information
              </button>
            </div>
          </div>
        </div>
        <!-- Native Species -->
        <div v-if="content.native_species.length">
          <h3 class="text-2xl font-semibold mb-4">Native Species</h3>
          <div v-for="animal in content.native_species" :key="animal.name" class="bg-lightBlue p-4 rounded-lg shadow-md mb-4">
            <h4 class="text-xl font-bold">{{ animal.name }}</h4>
            <p>{{ animal.description }}</p>
            <div class="flex justify-end mt-2 space-x-2">
              <button
                  :href="animal.wikipedia_url"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="text-gray-600 bg-white border border-gray-300 rounded-lg p-2 hover:bg-gray-100 shadow-md"
                  aria-label="Wikipedia"
              >
                <i class="fab fa-wikipedia-w"></i>
              </button>
              <button
                  @click="openModalWithImages('Animal ' + animal.name)"
                  class="text-white bg-blue-500 hover:bg-blue-600 font-medium rounded-lg px-4 py-2 shadow-md"
              >
                <i class="fas fa-camera mr-2"></i>
                View Images
              </button>
              <button
                  @click="fetchMoreInformation('Animal ' + animal.name)"
                  class="text-white bg-green-500 hover:bg-green-600 font-medium rounded-lg px-4 py-2 shadow-md"
              >
                <i class="fas fa-info-circle mr-2"></i>
                More Information
              </button>
            </div>
          </div>
        </div>
        <!-- Traditional Foods -->
        <div v-if="content.traditional_foods.length">
          <h3 class="text-2xl font-semibold mb-4">Traditional Foods</h3>
          <div v-for="food in content.traditional_foods" :key="food.name" class="bg-lightBlue p-4 rounded-lg shadow-md mb-4">
            <h4 class="text-xl font-bold">{{ food.name }}</h4>
            <p>{{ food.description }}</p>
            <div class="flex justify-end mt-2 space-x-2">
              <button
                  :href="food.wikipedia_url"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="text-gray-600 bg-white border border-gray-300 rounded-lg p-2 hover:bg-gray-100 shadow-md"
                  aria-label="Wikipedia"
              >
                <i class="fab fa-wikipedia-w"></i>
              </button>
              <button
                  @click="openModalWithImages('Food ' + food.name)"
                  class="text-white bg-blue-500 hover:bg-blue-600 font-medium rounded-lg px-4 py-2 shadow-md"
              >
                <i class="fas fa-camera mr-2"></i>
                View Images
              </button>
              <button
                  @click="fetchMoreInformation('Food ' + food.name)"
                  class="text-white bg-green-500 hover:bg-green-600 font-medium rounded-lg px-4 py-2 shadow-md"
              >
                <i class="fas fa-info-circle mr-2"></i>
                More Information
              </button>
            </div>
          </div>
        </div>
        <!-- Famous People -->
        <div v-if="content.famous_people.length">
          <h3 class="text-2xl font-semibold mb-4">Famous People</h3>
          <div v-for="person in content.famous_people" :key="person.name" class="bg-lightBlue p-4 rounded-lg shadow-md mb-4">
            <h4 class="text-xl font-bold">{{ person.name }}</h4>
            <p>{{ person.description }}</p>
            <div class="flex justify-end mt-2 space-x-2">
              <button
                  :href="person.wikipedia_url"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="text-gray-600 bg-white border border-gray-300 rounded-lg p-2 hover:bg-gray-100 shadow-md"
                  aria-label="Wikipedia"
              >
                <i class="fab fa-wikipedia-w"></i>
              </button>
              <button
                  @click="openModalWithImages(person.name)"
                  class="text-white bg-blue-500 hover:bg-blue-600 font-medium rounded-lg px-4 py-2 shadow-md"
              >
                <i class="fas fa-camera mr-2"></i>
                View Images
              </button>
              <button
                  @click="fetchMoreInformation(person.name)"
                  class="text-white bg-green-500 hover:bg-green-600 font-medium rounded-lg px-4 py-2 shadow-md"
              >
                <i class="fas fa-info-circle mr-2"></i>
                More Information
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
      v-if="isModalOpen"
      class="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50"
  >
    <div class="bg-white w-3/4 max-w-4xl rounded-lg shadow-lg p-6 relative">
      <h2 class="text-2xl font-semibold mb-4">Images for {{ selectedPlace }}</h2>
      <button
          @click="closeModal"
          class="absolute top-4 right-4 bg-red-500 text-white font-bold text-lg w-10 h-10 rounded-full flex items-center justify-center hover:bg-red-600 shadow-lg"
          aria-label="Close Modal"
      >
        <i class="fas fa-times"></i>
      </button>

      <!-- Featured Image -->
      <div class="relative mb-4 text-center">
        <!-- Loading Spinner -->
        <div v-if="loading" class="flex justify-center items-center h-[70vh]">
          <div class="loader"></div>
        </div>
        <!-- Image -->
        <img
            v-show="!loading"
            :src="images[currentImageIndex]?.link"
            :alt="images[currentImageIndex]?.title"
            class="w-full max-h-[70vh] object-contain rounded-lg shadow-md"
            @load="onImageLoad"
            @error="onImageError"
        />
        <!-- Image Title -->
        <p class="mt-4 text-lg font-medium text-gray-700">
          {{ images[currentImageIndex]?.title }}
          <a
              :href="images[currentImageIndex]?.contextLink"
              target="_blank"
              rel="noopener noreferrer"
              class="block text-xs text-blue-500 underline mt-1"
          >
            View Source
          </a>
        </p>
        <!-- Previous Image Button -->
        <button
            @click="prevImage"
            class="absolute left-2 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white w-10 h-10 rounded-full flex items-center justify-center hover:bg-gray-700 shadow-lg"
            aria-label="Previous Image"
        >
          <i class="fas fa-chevron-left"></i>
        </button>

        <!-- Next Image Button -->
        <button
            @click="nextImage"
            class="absolute right-2 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white w-10 h-10 rounded-full flex items-center justify-center hover:bg-gray-700 shadow-lg"
            aria-label="Next Image"
        >
          <i class="fas fa-chevron-right"></i>
        </button>
      </div>
      <!-- Thumbnail Carousel -->
      <div class="flex overflow-x-auto space-x-2 py-4">
        <div
            v-for="(image, index) in images"
            :key="image.link"
            @click="setCurrentImage(index)"
            class="cursor-pointer border-2 rounded-lg p-1"
            :class="{
          'border-blue-500': index === currentImageIndex,
          'border-gray-300': index !== currentImageIndex,
        }"
        >
          <img
              :src="image.thumbnail"
              :alt="image.title"
              class="w-24 h-24 object-cover rounded-md"
          />
        </div>
      </div>
    </div>
  </div>

  <!-- Detailed Information Modal -->
  <div v-if="isMoreInfoModalOpen" class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
    <div class="bg-white p-8 rounded-lg max-w-5xl w-11/12 h-4/5 overflow-hidden relative">
      <!-- Close Button -->
      <button
          @click="closeMoreInfoModal"
          class="absolute top-4 right-4 bg-red-500 text-white font-bold text-lg w-10 h-10 rounded-full flex items-center justify-center hover:bg-red-600 shadow-lg"
          aria-label="Close Modal"
      >
        <i class="fas fa-times"></i>
      </button>
      <!-- Loader -->
      <div v-if="loadingMoreInfo" class="flex justify-center items-center h-full">
        <div class="loader"></div>
      </div>
      <!-- Render HTML Content -->
      <div
          v-else
          class="overflow-y-auto h-full px-4"
          v-html="moreInformation"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalWindow',
  data() {
    return {
      images: [], // To hold images fetched from the backend
      selectedPlace: null, // Current place selected
      isModalOpen: false, // Modal open state
      currentImageIndex: 0, // Index of the currently displayed image
      loading: true, // Loading state for the image
      loadingMoreInfo: false, // Loading state for detailed information
      moreInformation: '', // Holds the more detailed information
      isMoreInfoModalOpen: false,

    };
  },
  props: {
    content: {
      type: Object,
      required: true
    },
    isOpen: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    flagUrl() {
      return `https://flagpedia.net/data/flags/w580/${this.content.country_code.toLowerCase()}.webp?v=un`;
    }
  },
  methods: {
    closeModal() {
      if (this.isModalOpen) {
        this.isModalOpen = false;
      } else if (this.isMoreInfoModalOpen) {
        this.isMoreInfoModalOpen = false;
      } else {
        console.log('not modal not more info');
        this.currentImageIndex = 0;
        this.loading = true;
        this.$emit('close');
        document.removeEventListener('keydown', this.handleKeydown);
      }
    },
    handleKeydown(event) {
      if (event.key === 'Escape') {
        this.closeModal(); // Close modal when Escape is pressed
      }
      console.log('handleKeydown', event);
    },
    async fetchImages(query) {
      try {
        const response = await fetch(`${process.env.VUE_APP_API_URL}/api/images?query=${query}`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        this.images = await response.json(); // Assign the parsed data to the images array
        this.loading = false; // Stop loading spinner when images are fetched
      } catch (error) {
        console.error('Error fetching images:', error);
        this.images = [];
        this.loading = false;
      }
    },
    async openModalWithImages(placeName) {
      this.selectedPlace = placeName;
      this.loading = true; // Set loading to true when opening the modal
      await this.fetchImages(placeName); // Fetch images for the place
      this.isModalOpen = true; // Open the modal
      document.addEventListener('keydown', this.handleKeydown); // Add event listener
    },
    setCurrentImage(index) {
      this.currentImageIndex = index;
      this.loading = true; // Show loading spinner when switching images
    },
    prevImage() {
      if (this.currentImageIndex > 0) {
        this.currentImageIndex -= 1;
      } else {
        this.currentImageIndex = this.images.length - 1; // Cycle to the last image
      }
      this.loading = true; // Show loading spinner when switching images
    },
    nextImage() {
      if (this.currentImageIndex < this.images.length - 1) {
        this.currentImageIndex += 1;
      } else {
        this.currentImageIndex = 0; // Cycle back to the first image
      }
      this.loading = true; // Show loading spinner when switching images
    },
    onImageLoad() {
      this.loading = false; // Hide loading spinner when image is loaded
    },
    onImageError() {
      this.loading = false; // Hide loading spinner even on error
      console.error('Failed to load image.');
    },
    closeMoreInfoModal() {
      this.isMoreInfoModalOpen = false;
      this.moreInformation = '';
    },
    async fetchMoreInformation(placeName) {
      this.loadingMoreInfo = true;
      this.selectedPlace = placeName;
      this.isMoreInfoModalOpen = true;

      try {
        const response = await fetch(`${process.env.VUE_APP_API_URL}/api/moreInfo?query=${encodeURIComponent(placeName)}`);
        if (!response.ok) {
          throw new Error('Failed to fetch more information');
        }

        const data = await response.json();
        this.moreInformation = data.description || 'No additional information available.';
      } catch (error) {
        console.error(error);
        this.moreInformation = 'An error occurred while fetching the information.';
      } finally {
        this.loadingMoreInfo = false;
      }
    },
  }
};
</script>

<style scoped>
@import '~@fortawesome/fontawesome-free/css/all.css';
/* Optional custom styles for carousel arrows */
.carousel-arrow {
  width: 40px;
  height: 40px;
}
.carousel-arrow:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

@import '~@fortawesome/fontawesome-free/css/all.css';

/* Loader Styles */
.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>
