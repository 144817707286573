<template>
  <div class="map-container" :class="{ 'h-[80vh]': isMobile, 'h-full': !isMobile }">
    <!-- Placeholder for Google Maps -->
    <div id="map" class="map-container"></div>
  </div>
</template>

<script>
export default {
  name: 'GoogleMap',
  props: {
    activeTab: {
      type: String,
      required: true
    },
    quizCountryName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      markers: [], // Array to store markers
      isMobile: window.innerWidth <= 768, // Detect mobile screen size
    };
  },
  mounted() {
    window.addEventListener('resize', this.checkScreenSize);
    this.initMap();
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkScreenSize);
  },
  methods: {
    checkScreenSize() {
      this.isMobile = window.innerWidth <= 768;
    },
    initMap() {
      /* global google */
      this.map = new google.maps.Map(document.getElementById('map'), {
        center: { lat: -36.740375344797926, lng: 174.6885030042015 },
        zoom: 10,
        mapTypeId: 'hybrid',
        gestureHandling: 'greedy',
      });

      // Add a click listener to the map
      this.map.addListener('click', (event) => {
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();
        const geocoder = new google.maps.Geocoder();
        const latLng = event.latLng;

        // Add marker for the clicked location
        this.addMarker(latLng);

        // Perform reverse geocoding
        geocoder.geocode({ location: latLng }, (results, status) => {
          if (status === 'OK' && results[0]) {
            const addressComponents = results[0].address_components;
            let country, state, city;

            addressComponents.forEach((component) => {
              const types = component.types;
              if (types.includes('country')) country = component.long_name;
              if (types.includes('administrative_area_level_1')) state = component.long_name;
              if (types.includes('locality')) city = component.long_name;
            });

            if (this.activeTab !== 'quiz') {
              if (country) {
                this.fetchPlacesInfo(`${city || ''}, ${state || ''}, in ${country}`);
              } else {
                this.fetchCoordinatesInfo(lat, lng);
              }
            } else {
              // Handle quiz functionality
              if (country === this.quizCountryName) {
                this.$emit('quiz-complete', { lat, lng });
              } else {
                this.$emit('next-clue');
              }
            }
          } else {
            this.fetchCoordinatesInfo(lat, lng);
          }
        });
      });
    },
    // Adds a marker to the map and stores it in the array
    addMarker(location, icon = null) {
      const marker = new google.maps.Marker({
        position: location,
        map: this.map,
        icon: icon,
      });
      this.deleteMarkers();
      this.markers.push(marker);
    },
    // Sets the map on all markers in the array
    setMapOnAll(map) {
      this.markers.forEach(marker => marker.setMap(map));
    },
    // Hides all markers from the map
    hideMarkers() {
      this.setMapOnAll(null);
    },
    // Deletes all markers by hiding them and clearing the array
    deleteMarkers() {
      this.hideMarkers();
      this.markers = [];
    },
    async fetchPlacesInfo(placeName) {
      this.$emit('loading', true);
      try {
        const response = await fetch(`${process.env.VUE_APP_API_URL}/api/placeInfo?placeName=${encodeURIComponent(placeName)}`);
        const data = await response.json();
        this.$emit('update-info', data);
      } catch (error) {
        console.error('Error fetching place info:', error);
      } finally {
        this.$emit('loading', false);
      }
    },
    async fetchCoordinatesInfo(lat, lng) {
      this.$emit('loading', true);
      try {
        const response = await fetch(`${process.env.VUE_APP_API_URL}/api/coordinates?lat=${lat}&lng=${lng}`);
        const data = await response.json();
        this.$emit('update-info', data);
      } catch (error) {
        console.error('Error fetching coordinates info:', error);
      } finally {
        this.$emit('loading', false);
      }
    }
  },
  watch: {
    activeTab(newTab) {
      if (newTab !== 'quiz') {
        this.deleteMarkers();
      }
    }
  }
};
</script>

<style scoped>
#map {
  height: 100%; /* Default height */
}
.map-container {
  position: relative;
  width: 100%;
}
</style>
