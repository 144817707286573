<!-- src/components/InfoTab.vue -->
<template>
  <div>
    <div class="text-center mb-4">
      <img :src="flagUrl" alt="Country flag" class="w-32 mx-auto">
      <h2 class="text-2xl font-bold">{{ infoData.country }}</h2>

      <p class="text-xl">{{ infoData.territorial_divisions.region }}, {{ infoData.territorial_divisions.city }}</p>
    </div>
    <p class="mb-4">{{ infoData.description }}</p>
    <p class="mb-4">{{ infoData.history }}</p>
    <h3 class="text-lg font-bold mb-2">Famous Places</h3>
    <ul>
      <li v-for="place in infoData.famous_places" :key="place.name" class="mb-2">
        <strong>{{ place.name }}:</strong> {{ place.description }}
      </li>
    </ul>
    <button @click="openModal" class="mt-4 bg-blue-500 text-white p-2 rounded">Show All Details</button>
  </div>
</template>

<script>
export default {
  name: 'InfoTab',
  props: {
    infoData: {
      type: Object,
      required: true
    },
    flagUrl: {
      type: String,
      required: true
    }
  },
  methods: {
    openModal() {
      console.log('openModal');

      document.addEventListener('keydown', this.handleKeydown); // Add event listener

      this.$emit('open-modal');
    },
    closeModal() {
      console.log('closeModal method');
      this.$emit('close');
      document.removeEventListener('keydown', this.handleKeydown); // Remove event listener
    },
  }
};
</script>

<style scoped>
/* Add any additional styling here */
</style>
