<template>
  <!-- Sidebar Wrapper -->
  <div
      :class="`
      transition-all duration-300
      ${isMobile ? 'fixed bottom-0 w-full h-[20vh] flex flex-col bg-gray-800' : 'flex flex-col h-full'}
      ${isOpen ? (isMobile ? '' : 'w-80') : (isMobile ? '' : 'w-16')}
    `"
  >
    <!-- Mobile Layout -->
    <template v-if="isMobile">
      <!-- Loading State -->
      <div v-if="loading" class="flex items-center justify-center flex-col w-full h-full text-white">
        <div class="loader mb-4"></div>
      </div>

      <!-- Info Display -->
      <div v-else-if="hasInfoData" class="flex w-full h-1/2 p-2 text-white">
        <!-- Flag (30% of the row) -->
        <div class="flex items-center justify-center w-3/12">
          <img :src="flagUrl" alt="Flag" class="w-16 h-16 object-contain rounded-full" />
        </div>
        <!-- Place Name and Description (70% of the row) -->
        <div class="w-9/12">
          <h2 class="text-lg font-semibold truncate">
            {{ infoData.name }}, {{ infoData.territorial_divisions.region }}, {{ infoData.country }}
          </h2>
          <p class="text-sm">{{ infoData.description || 'No description available' }}</p>
        </div>
      </div>

      <!-- Show More Info Button (Full Second Row) -->
      <div v-if="hasInfoData" class="flex w-full h-auto p-2">
        <button
            @click="openModal(infoData.url || '#')"
            class="w-full py-2 bg-blue-500 text-white rounded text-center"
        >
          Show More Info
        </button>
      </div>

      <!-- Default Message -->
      <div v-else class="flex items-center justify-center h-full">
        <p class="text-lg text-gray-400 font-medium text-center">
          {{ currentLoadingMessage || `Click on a place to start exploring`}}
        </p>
      </div>
    </template>

    <!-- Tablet/Desktop Layout -->
    <template v-else>
      <button @click="toggleSidebar" class="mb-4 p-2 bg-blue-500 text-white flex justify-center">
        <i :class="`fas ${isOpen ? 'fa-chevron-left' : 'fa-bars'}`"></i>
      </button>
      <div v-if="isOpen" class="flex flex-col flex-grow overflow-y-auto">
        <!-- Tabs -->
        <div class="flex justify-around mb-4">
          <button @click="setActiveTab('info')" :class="{'bg-blue-500 text-white': activeTab === 'info'}" class="p-2">
            Info
          </button>
          <button @click="setActiveTab('quiz')" :class="{'bg-blue-500 text-white': activeTab === 'quiz'}" class="p-2">
            Quiz
          </button>
        </div>
        <!-- Content -->
        <div class="flex-grow overflow-y-auto p-4">
          <div v-if="loading" class="flex justify-center items-center h-full">
            <div class="loader"></div>
          </div>
          <div v-else>
            <div v-if="activeTab === 'info' && hasInfoData">
              <InfoTab :infoData="infoData" :flagUrl="flagUrl" @open-modal="openModal" @close="isModalOpen = false" />
            </div>
          </div>
          <div v-if="activeTab === 'quiz'">
            <CountryQuiz @quiz-country-name="updateQuizCountryName" @update-score="updateScore" ref="quiz" />
          </div>
        </div>
      </div>
    </template>
    <ModalWindow :url="modalUrl" :isOpen="isModalOpen" @close="isModalOpen = false" />
    <InfoModalWindow :content="infoData" :isOpen="isModalOpen" @close="isModalOpen = false" />
  </div>
</template>

<script>
import ModalWindow from './ModalWindow.vue';
import CountryQuiz from './CountryQuiz.vue';
import InfoTab from './InfoTab.vue';
import InfoModalWindow from './InfoModalWindow.vue';

export default {
  name: 'SidebarMenu',
  components: {
    ModalWindow,
    CountryQuiz,
    InfoTab,
    InfoModalWindow,
  },
  data() {
    return {
      isOpen: true,
      activeTab: 'info',
      infoData: {},
      countryCode: '',
      isModalOpen: false,
      modalUrl: '',
      loading: false,
      totalScore: 0,
      isMobile: window.innerWidth <= 768, // Initial check for mobile
      loadingMessages: [
        "🔍 Researching about place information...",
        "📜 Studying its rich history...",
        "🏛️ Finding out famous landmarks...",
        "🌿 Investigating endemic species...",
        "🌟 Figuring out who the famous people are...",
      ],
      currentLoadingMessage: "",
      loadingInterval: null,
    };
  },
  mounted() {
    window.addEventListener('resize', this.checkScreenSize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkScreenSize);
    clearInterval(this.loadingInterval);
  },
  methods: {
    checkScreenSize() {
      this.isMobile = window.innerWidth <= 768;
    },
    toggleSidebar() {
      this.isOpen = !this.isOpen;
      this.$emit('toggle', this.isOpen);
    },
    updateInfo(data) {
      this.infoData = data;
      this.countryCode = data.country_code.toLowerCase();
      this.activeTab = 'info';
      this.loading = false;
    },
    startLoadingMessages() {
      let index = 0;
      this.currentLoadingMessage = this.loadingMessages[index];
      console.log(this.currentLoadingMessage);
      this.loadingInterval = setInterval(() => {
        index = (index + 1) % this.loadingMessages.length;
        this.currentLoadingMessage = this.loadingMessages[index];
        console.log(this.currentLoadingMessage);
      }, 3000); // Change message every 3 seconds
    },
    openModal(url) {
      this.modalUrl = url;
      this.isModalOpen = true;
    },
    setLoading(isLoading) {
      this.loading = isLoading;
      if (isLoading) {
        this.infoData = {};
        this.startLoadingMessages();
      } else {
        clearInterval(this.loadingInterval);
      }
    },
    updateQuizCountryName(name) {
      this.$emit('quiz-country-name', name);
    },
    setActiveTab(tab) {
      this.activeTab = tab;
      this.$emit('update-active-tab', tab);
    },
    completeQuiz() {
      this.$refs.quiz.completeQuiz();
    },
    updateScore(score) {
      this.totalScore += score;
    },
    nextClue() {
      this.$refs.quiz.nextClue();
    },
  },
  computed: {
    flagUrl() {
      return `https://flagpedia.net/data/flags/w580/${this.countryCode}.webp?v=un`;
    },
    hasInfoData() {
      return Object.keys(this.infoData).length > 0;
    },
  },
};
</script>

<style scoped>
@import '~@fortawesome/fontawesome-free/css/all.css';

.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 64px;
  height: 64px;
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
